


















import { Vue, Component, Prop } from 'vue-property-decorator'

import StatsHearts from '@/components/charts/StatsHearts.vue'
import ChartBase from '@/components/charts/ChartBase.vue'

@Component({
  components: {
    ChartBase,
    StatsHearts,
  },
})
export default class StatsBlock extends Vue {
  @Prop({ default: 'heart' })
  private type!: 'heart' | 'lesson' | 'exercise'

  @Prop({ default: 0 })
  private amount!: number

  @Prop({ default: '' })
  private title!: string

  @Prop({ default: null })
  private percentPrimary!: number

  @Prop({ default: null })
  private percentSecondary!: number

  @Prop({ default: true })
  private isLarge!: boolean
}
