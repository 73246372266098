import { render, staticRenderFns } from "./MasterDashboardStats.vue?vue&type=template&id=024ca7a1&scoped=true&lang=pug&"
import script from "./MasterDashboardStats.vue?vue&type=script&lang=ts&"
export * from "./MasterDashboardStats.vue?vue&type=script&lang=ts&"
import style0 from "./MasterDashboardStats.vue?vue&type=style&index=0&id=024ca7a1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "024ca7a1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCardText})
