































import { Component, Prop, Vue } from 'vue-property-decorator'

// Store
import { DashboardEducationStatsBlockResource } from '@/store/types'
// Components
import ChartBase from '@/components/charts/ChartBase.vue'
import Tag from '@/components/_uikit/Tag.vue'
import StatsBlock from '@/components/StatsBlock.vue'
// Helpers
import { declension } from '@/utils/functions'

@Component({
  components: {
    ChartBase,
    StatsBlock,
    Tag,
  },
})
export default class DashboardStats extends Vue {
  @Prop({ default: null })
  private stats!: DashboardEducationStatsBlockResource

  @Prop({ default: false })
  private isMasterGroupLite!: boolean

  private get percentCheckedLessons() {
    if (this.stats.totalLessons !== 0 && this.stats.checkedLessons !== 0) {
      return Math.floor((this.stats.checkedLessons / this.stats.totalLessons) * 100)
    }
    return 0
  }

  private get currentMonth() {
    const months = ['Январь', 'Февраль', 'март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

    return months[(new Date()).getMonth()].toLowerCase()
  }

  private get percentCompletedExcersies() {
    if (this.stats.totalExercises !== 0 && this.stats.completeExercises !== 0) {
      return Math.floor((this.stats.completeExercises / this.stats.totalExercises) * 100)
    }
    return 0
  }

  private get percentAvailableExcersises() {
    if (this.stats.totalExercises !== 0 && this.stats.availableExercises !== 0) {
      return Math.floor((this.stats.availableExercises / this.stats.totalExercises) * 100)
    }
    return 0
  }

  private get isLarge() {
    return this.$vuetify.breakpoint.width > 1025
  }

  private declension (n: number, t: string[]) {
    return declension(n, t)
  }
}
