





































import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import VueRouter from 'vue-router'

// types
import { UserCourseStatisticResource } from '@/store/types/schema.progress'
import { CourseSubjectType } from '@/store/types/progress'
// components
import Tag from '@/components/_uikit/Tag.vue'
import CardWrapper from '@/components/_uikit/CardWrapper.vue'
// mixins
import MasterProgressMixin from '@/mixins/master/progress/MasterProgressMixin'
import MasterProgressCourseMixin from '@/mixins/master/progress/MasterProgressCourseMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

const { isNavigationFailure, NavigationFailureType } = VueRouter

@Component({
  components: {
    CardWrapper,
    Tag,
  },
})
export default class MasterDashboardCourseProgress extends mixins(
  MasterProgressMixin,
  MasterProgressCourseMixin,
  NotifyMixin,
) {
  @Prop({ required: true })
  courseStatistic!: UserCourseStatisticResource

  @Prop({ required: true })
  isLoaded!: boolean

  @Prop({ required: true })
  isLoadedFailed!: boolean

  private get percent() {
    return this.courseStatistic.percent
  }

  private get percentText() {
    return `${this.percent}% курса пройдено`
  }

  private get rankName() {
    return this.courseStatistic.currentRank.name || ''
  }

  private get rankText() {
    if (!this.courseStatistic) return ''

    return this.getTagText(this.courseStatistic.currentRank.position)
  }

  private get courseColor() {
    return this.getSubjectColor(this.courseStatistic?.currentRank?.course?.subject as CourseSubjectType) ?? 'red'
  }

  private get isSpecial () {
    return this.courseStatistic?.currentRank?.course?.type === 'special' ?? false
  }

  private get courseImage() {
    if (!this.courseStatistic) return ''

    return this.getRankIcon(this.courseStatistic.currentRank.position, this.isSpecial)
  }

  private get courseId () {
    return this.courseStatistic?.currentRank?.course?.id?.toString()
  }

  private redirectToLvl() {
    if (!this.courseStatistic) return

    if (this.courseId && this.courseId !== '0') {
      this.$router.push({
        name: 'master.progress.course',
        params: {
          courseID: this.courseId,
        },
      }).catch((err) => {
        if (!isNavigationFailure(err, NavigationFailureType.redirected)) {
          Promise.reject(err)
        }
      })
    } else {
      this.notifyError('Что-то пошло не так, пожалуйста, перезагрузите странцу...')
    }
  }
}
