










import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import Heart from '@/components/Heart.vue'

@Component({
  components: {
    Heart,
  },
})
export default class StatsHearts extends Vue {
  @Prop({ required: true })
  private quantity!: number

  @Prop({ required: true })
  private width!: number
}
