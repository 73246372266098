


























































































































import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

// utils
import { formatDateRange, parseDateToMilliseconds } from '@/utils/functions'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import CoursesSettings from '@/components/CoursesSettings.vue'
import DashboardMentor from '@/components/views/dashboard/DashboardMentor.vue'
import DashboardSchedule from '@/components/views/dashboard/DashboardSchedule.vue'
import DashboardCalendar from '@/components/views/dashboard/DashboardCalendar.vue'
import MasterDashboardExercises from '@/components/views/dashboard/master/MasterDashboardExercises.vue'
import MasterDashboardStats from '@/components/views/dashboard/master/MasterDashboardStats.vue'
import MasterDashboardLessons from '@/components/views/dashboard/master/MasterDashboardLessons.vue'
import MasterDashboardCourse from '@/components/views/dashboard/master/MasterDashboardCourse.vue'
import MasterDashboardCourseProgress from '@/components/views/dashboard/master/MasterDashboardCourseProgress.vue'
import DocsAgreementModal from '@/components/modals/DocsAgreement.vue'
// store
import MasterDashboardModule from '@/store/modules/master/dashboard'
import MasterTutorialsModule from '@/store/modules/master/tutorials'
// types
import WelcomeCard from '@/components/cards/WelcomeCard.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import MasterEducationModule from '@/store/modules/master/education'
import CurrentTimeMixin from '@/mixins/CurrentTimeMixin'
import AuthModule from '@/store/modules/auth'
import { formatDate } from '@/utils/functions'
import MasterProgressModule from '@/store/modules/master/progress'
import MasterDashboardRepetition from '@/components/views/dashboard/master/MasterDashboardRepetition.vue'
import DashboardHelp from '@/components/views/dashboard/DashboardHelp.vue'

@Component({
  components: {
    CoursesSettings,
    DashboardCalendar,
    DashboardHelp,
    DashboardMentor,
    DashboardSchedule,
    DocsAgreementModal,
    MasterDashboardCourse,
    MasterDashboardCourseProgress,
    MasterDashboardExercises,
    MasterDashboardLessons,
    MasterDashboardRepetition,
    MasterDashboardStats,
    WelcomeCard,
  },
})
export default class MasterDashboard extends Mixins(MasterGroupMixin, NotifyMixin, CurrentTimeMixin) {
  private isLoaded = false // Note: это нужно, так как Schedule нужно размаунтить для получения новых данных
  private isLoadedStats = true
  private isLoadedStatsFailed = false
  private isWelcomeState = false
  private currentIndexCourse = MasterDashboardModule.findIndexCourse !== -1 ? MasterDashboardModule.findIndexCourse : 0

  private get user() {
    return AuthModule.self
  }

  private get isMonthEnd() {
    const date = new Date().getDate()
    return date >= 25 && date <= 31
  }

  private get mentorPeriod() {
    if (this.currentMasterGroup.dates.assigningEndAt && this.currentMasterGroup.dates.assigningStartAt && this.currentTime < parseDateToMilliseconds(this.currentMasterGroup.dates.assigningEndAt)) {
      return {
        label: 'в период с',
        period: formatDateRange(this.currentMasterGroup.dates.assigningStartAt, this.currentMasterGroup.dates.assigningEndAt),
      }
    }

    return {
      label: 'через',
      period: '1–3 дня',
    }
  }

  private hasDocsAgreement = false

  private handleLoginAs() {
    this.$router.push('/manager')
  }

  private handleRedirectToCourses() {
    this.$router.push({ name: 'master.courses.my' })
  }

  private get dashboard() {
    return MasterDashboardModule.dashboard
  }

  private get courseStatistic () {
    return MasterProgressModule.courseStatistic
  }

  private get hasSocials () {
    if (AuthModule.self) {
      return AuthModule.self.hasSocials
    }
    return false
  }

  private get startDateCourse () {
    if (this.currentMasterGroupDates && this.currentMasterGroupDates.length) {
      return this.currentMasterGroupDates[2] ?? this.currentMasterGroupDates[0]
    }
    return null
  }

  private get isStartedCourse () {
    if (this.startDateCourse) {
      return this.currentTime > parseDateToMilliseconds(this.startDateCourse)
    }
    return false
  }

  private get prolongationData () {
    if (this.isMaster && MasterDashboardModule.dashboard) {
      return MasterDashboardModule.dashboard.masterNeedNextMonthBlock
    }
    return null
  }

  private get recurringData() {
    if (this.isMaster && MasterDashboardModule.dashboard) {
      return MasterDashboardModule.dashboard.recurringPaymentBlock
    }
    return true
  }

  private get isMainTourActive() {
    return MasterTutorialsModule.mainTutorialIsActive
  }

  private async mounted() {
    const findCourseIndex = this.masterGroupsFull.findIndex(group => group.id === this.currentMasterGroupID)
    if (this.currentMasterGroupID !== -1 && findCourseIndex === -1) {
      if (this.masterGroupsFull.length) {
        this.$bus.$emit('resetMonthExerciseFilter')
        this.handleTabChange(0, true)
        this.changeCurrentIndexCourse({ fetchDashboard: false, masterGroupID: this.currentMasterGroupID })
      }
    } else {
      if (findCourseIndex !== MasterDashboardModule.findIndexCourse) {
        this.handleTabChange(findCourseIndex)
      }
    }
    this.$bus.$on('changeCurrentIndexCourse', this.changeCurrentIndexCourse)

    this.hasDocsAgreement = Boolean(this.user && (!this.user.agreements.processingOfPersonalData || !this.user.agreements.confidentialityOfPersonalData))

    if (this.masterGroups.length === 0) {
      this.isWelcomeState = true
    } else {
      this.fetchDashboard(this.currentMasterGroup.course.id)
    }
  }

  private destroyed() {
    this.$bus.$off('changeCurrentIndexCourse', this.changeCurrentIndexCourse as any)
  }

  private changeCurrentIndexCourse(payload: { fetchDashboard: boolean, masterGroupID: number }) {
    this.currentIndexCourse = MasterEducationModule.masterGroups.filter(group => group.dashboardShow).findIndex(group => group.id === payload.masterGroupID)

    if (payload.fetchDashboard) {
      this.fetchDashboard(this.currentMasterGroup.course.id)
    }
  }

  private handleTabChange(masterGroupIndex: number, resetFilter = false) {
    // клик по табу создает сайд эффект, чтобы фильтр на других страницах получили актуальную текущую группу
    MasterDashboardModule.setFindIndex(masterGroupIndex)
    this.currentIndexCourse = masterGroupIndex
    if (this.masterGroupsFull && this.masterGroupsFull[this.currentIndexCourse])
      this.currentMasterGroupID = this.masterGroupsFull[this.currentIndexCourse].id as number
    if (resetFilter)
      this.$bus.$emit('resetMonthExerciseFilter')
  }

  private fetchDashboard(courseId: number) {
    MasterProgressModule.unsetCourseStatistic()
    this.isLoaded = false
    MasterDashboardModule.fetchDashboard(courseId)
      .then(() => this.fetchCourseProgress(courseId))
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          this.notifyError('Курс не найден. Перезагрузите страницу')
        } else {
          this.notifyError(error)
        }
      })
      .finally(() => this.isLoaded = true)
  }

  private fetchCourseProgress(courseId: number) {
    this.isLoadedStats = true
    if (this.currentMasterGroup.course.hasGamification) {
      MasterProgressModule.fetchCourseProgressStatistic({ courseId, loading: false })
        .then(() => {
          this.isLoadedStats = false
        })
        .catch(() => {
          this.isLoadedStats = false
          this.isLoadedStatsFailed = true
        })
    } else {
      this.isLoadedStats = false
    }
  }

  private prolongateCourse() {
    if (this.prolongationData) {
      this.$bus.$emit('show-prolongation-modal', this.prolongationData.courseId)
    }
  }

  private formatDate(date: string, mask: string) {
    return formatDate(date, mask)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Дашборд',
    }
  }
}
