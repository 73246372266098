






































import { Component, Prop } from 'vue-property-decorator'

// types
import {
  EducationMasterGroupResource,
  NameValueResource,
} from '@/store/types'
// components
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import Tag from '@/components/_uikit/Tag.vue'
import CourseModal from '@/components/modals/courses/CourseModal.vue'
// mixins
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import { declension, parseDateToMilliseconds } from '@/utils/functions'
// store
import DictionaryModule from '@/store/modules/dictionary'

@Component({
  components: {
    ButtonArrow,
    CourseModal,
    Tag,
  },
})
export default class DashboardCourse extends MasterGroupMixin {

  @Prop({ default: {} })
  private courseData!: EducationMasterGroupResource

  private days = 0
  private hours = 0
  private minutes = 0
  private seconds = 0

  private showCourseModal = false

  private get currentTime() {
    return DictionaryModule.currentTime
  }

  private get currentDeadlineTime() {
    return this.timeUntillDeadLine(this.currentMasterGroup.dates.open ?? this.currentMasterGroup.dates.start)
  }

  private selectedCourseID: number | null = null

  private mounted() {
    if (this.currentMasterGroup) {
      this.selectedCourseID = this.currentMasterGroup.course.id
    }
  }

  private timeUntillDeadLine(date: string): NameValueResource[] {
    const deadline = parseDateToMilliseconds(date)
    const secondsTill = (deadline - this.currentTime) / 1000
    let result: NameValueResource[] = []

    this.days = Math.floor((secondsTill / 86400))
    this.hours = Math.floor((secondsTill - this.days * 86400) / 3600)

    this.minutes = Math.floor((secondsTill - (this.hours * 3600 + this.days * 86400) ) / 60)
    this.seconds = Math.floor(secondsTill - (this.minutes * 60 + this.hours * 3600 + this.days * 86400))

    if (this.days < 0) {
      return result
    }

    if (this.days > 0) {
      result = [
        { name: declension(this.days, ['день', 'дня', 'дней']), value: this.days },
        { name: declension(this.hours, ['час', 'часа', 'часов']), value: this.hours },
      ]
    }

    else if (this.hours > 0) {
      result = [
        { name: declension(this.hours, ['час', 'часа', 'часов']), value: this.hours },
        { name: 'мин', value: this.minutes },
      ]
    }

    else if (this.minutes >= 0) {
      result = [
        { name: 'мин', value: this.minutes },
        { name: 'сек', value: this.seconds },
      ]
    }

    return result
  }

}
