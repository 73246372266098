































import { Component, Mixins, Prop } from 'vue-property-decorator'

import Dialog from '@/components/modals/Dialog.vue'
import FilesList from '@/components/FilesList.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import Tag from '@/components/_uikit/Tag.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { EducationMasterGroupResource } from '@/store/types'

@Component({
  components: {
    Dialog,
    FilesList,
    ModalWrapper,
    Tag,
  },
})
export default class CourseModal extends Mixins(NotifyMixin) {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ required: true })
  private courseData!: EducationMasterGroupResource

  private handleClose () {
    this.$emit('close')
  }
}
